@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card {
  width: 200px;
  height: 150px;
  background: #e4e3e3da;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 1em 1.5em;
  transition: all 0.5s ease;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card:hover {
  transform: scale(1.05);
  background: white;
}

.card:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

.custom-overlay {
  background-color: rgba(0, 0, 0, 0.1); /* Darker shade */
  backdrop-filter: blur(5px); /* Blur effect */
}
